////////////////////

// TODO: - modo più elengante per sovrascrivere il modulo
//       - formalizzare l'auto-stop dei paramentri "executed"
//       - formalizzare i parametri "executed"

import axios from 'axios';

import base_service from '../service';

const state = {
    obj               : {},
    loading           : false,
    loading_by_id     : false,
    error             : false,
    executed          : false,
    created           : false,
    updated           : false,
    deleted           : false,
    force_reload      : false,
    filters           : {},
    order             : null,
    selected          : false,
    upload_percentage : 0,
    map               : null,
    items             : null,
    page              : 1,
    tot               : 0,
    rows_per_page     : 0,
    params            : {},
};

const getters = {
    form_title: state => {
        return state.options.form_title === null ? `${ state.options.model_name.toUpperCase() } FORM` : state.options.title;
    },
    order: state => {
        return state.order === null ? state.options.default_order : state.order;
    },
    page: state => state.options.page,
    rows_per_page: state => state.options.rows_per_page,
    model_name: state => state.options.model_name,
    backend_url: ( state ) => {
        return state.options.config.backend_url;
    },
};

const actions = {
    get_all( { commit, state, getters }, data = {} ) { // TODO new
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        if ( data.params ) {
            Object.keys( data.params ).map( key => {
                if ( ![ '', null, undefined ].includes( data.params[ key ] ) ) {
                    params[ key ] = data.params[ key ];
                } else {
                    delete params[ key ];
                }
            });
        }
        var new_params_string = JSON.stringify( params, Object.keys(params).sort() );
        var old_params_string = JSON.stringify( state.params, Object.keys(state.params).sort() );
        var force_reload = new_params_string !== old_params_string;
        if ( force_reload ) { commit( 'set_params', { params } ); }
        const url = base_service.get_all( getters.backend_url, getters.model_name, params );
        if ( state.map === null || force_reload || state.force_reload === true || data.force_reload === true ) {
            axios.get( url )
                .then( res => {
                    let response = res.data;
                    if ( params.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response)
                    }
                })
                .catch( error => {
                    commit('error', error)
                });
        } else {
            commit('stop_request');
        }
    },
    change_page( { dispatch, commit }, page ) {
        commit('set_params', { page });
        dispatch( 'get_all', { force_reload: true, params: { page } } );
    },
    get_by_id({commit, getters}, data) {
        commit('start_request_id');
        const url = `${ getters.backend_url }/model/${ getters.model_name }/${ data.id }`;
        //state.service.get_by_id(getters.model_name, data.id, data.include)
        axios.get( url )
            .then( response => {
                let rows = response.data;
                commit('success_obj', rows);
                commit('success_select');
                commit('executed');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    create({ commit, getters }, data) {
        commit('start_request');
        const url = `${ getters.backend_url }/model/${ getters.model_name }/?include=${ data.include === true ? 'true' : 'false' }`;
        axios.post( url, data.payload )
            .then( obj => {
                commit('success', obj.data);
                commit('item_created');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    update({ commit, getters }, data) {
        commit('start_request');
        const url = `${ getters.backend_url }/model/${ getters.model_name }/${ data.id }?include=${ data.include === true ? 'true' : 'false' }`;
        axios.patch( url, data.payload )
            .then( response => {
                let obj = response.data;
                commit('success', obj);
                commit('executed');
                commit('item_updated');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    destroy( { commit, getters }, id ) {
        const url = `${ getters.backend_url }/model/${ getters.model_name }/${ id }`;
        axios.delete( url )
            .then( () => {
                commit('success_destroy');
            })
            .catch( error => {
                commit('error', error)
            });
    },
    force_reload({commit}) { commit('force_reload'); },
    executed({commit}) { commit('executed'); },
    created({commit}) { commit('created'); },
    clear({commit}) { commit('clear'); },
    set_filters( {commit}, filters ) { commit('setfilters', filters); },
    add_filter( {commit}, filter ) { commit('addfilter', filter); },
    remove_filter( {commit}, index ) { commit('removefilter', index); },
    set_order( {commit}, value ) { commit('set_order', value); },
    set_params( { commit }, obj ) { commit('set_params', obj); },
    add_param( {commit}, param ) { commit('addparam', param); },
    remove_param( {commit}, param ) { commit('removeparam', param); },
    success_list( { commit }, items ) { commit('success_list', items); }
};

const mutations = {
    executed( state ) {
        state.executed = true;
        setTimeout( () => {
            state.executed = false;
        }, 200);
    },
    set_order ( state, value ) { state.order = value; }, // ok
    setfilters( state, filters ) { state.filters = filters; },
    addfilter( state, filter ) {
        var index = Object.keys( filter )[0];
        state.params[ index ] = filter[ index ];
    },
    addparam( state, param ) {
        var index = Object.keys( param )[0];
        state.params[ index ] = param[ index ];
    },
    removefilter( state, index ) { delete state.params[ index ]; },
    removeparam( state, index ) { delete state.params[ index ]; },
    start_request_id( state ) {
        state.loading_by_id = true;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
    },
    start_request( state ) {
        state.loading      = true;
        state.error        = false;
        state.executed     = false;
    },
    stop_request( state ) {
        state.loading      = false;
        state.force_reload = false;
    },
    item_created( state ) {
        state.created = true;
        setTimeout( () => {
            state.created = false;
        }, 1000);
    },
    item_updated( state ) {
        state.updated = true;
        setTimeout( () => {
            state.updated = false;
        }, 1000);
    },
    success_destroy( state ) {
        state.deleted = true;
        setTimeout( () => { state.deleted = false; }, 250 );
    },
    success( state, obj ) { // per update e create
        if ( state.map && state.map.has( obj.id ) ) {
            state.map.set( obj.id, obj );
            var items = [];
            state.map.forEach( ( value ) => {
                items.push( value );
            });
            state.items = items;
        }
        state.obj           = obj;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        //state.executed = true;
        //setTimeout( () => {
        //    state.executed = false;
        //}, 200);
    },
    successcreate( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.created  = true;
        state.executed = true;
        setTimeout( () => {
            state.executed = false;
        }, 200);
    },
    success_obj( state, obj ) {
        state.obj      = obj;
        state.loading  = false;
        state.loading_by_id = false;
        state.error    = false;
        state.executed = true;
        setTimeout( () => {
            state.executed = false;
        }, 200);
    },
    success_select( state ) {
        state.selected = true;
    },
    success_list( state, items ) {
        var map = new Map();
        items.map( x => {
            map.set( x.id, x );
        });
        state.items         = items;
        state.map           = map;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.force_reload  = false;
        state.executed = true;
        setTimeout( () => {
            state.executed = false;
        }, 200);
    },
    error( state, error ) {
        state.obj          = error;
        state.loading      = false;
        state.error        = true;
        state.executed     = false;
        state.force_reload = true;
    },
    set_total( state, data ) { state.tot = data; },
    set_page( state, data ) { state.page = data; state.params.page = data; },
    set_rows_per_page( state, data ) { state.rows_per_page = data; },
    force_reload(state) { state.force_reload = true; },
//  executed(state) {
//      state.executed = false;
//      state.selected = false;
//  },
    created(state) { state.created = false; },
    clear( state ) {
        state.obj           = {};
        state.items         = null;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.executed      = false;
        state.force_reload  = false;
        state.selected      = false;
        state.filters       = {};
        state.params        = {};
   },
   set_params( state, obj ) {
       if ( typeof obj === 'object' ) {
           var keys = Object.keys( obj );
           for ( var i = 0; i < keys.length; i++  ) {
               if ( state.hasOwnProperty( keys[ i ] ) ) { state[ keys[ i ] ] = obj[ keys[ i ] ]; }
           }
       }
   },
   set_percentage( state, value ) {
       state.upload_percentage = value;
       if ( parseInt( value ) === 100 ) {
           setTimeout( () => {
               state.upload_percentage = 0;
           }, 1000);
       }
   }
};

////////////////////////////////////////////////////////////

class module_config { // overrides default_options with user-defined-options

    constructor( user_options = null ) {
        this.default_options = {
            send_alert     : true,
            form_title     : null,
            page           : 1,
            rows_per_page  : 25,
            default_order  : 'id DESC',
            model_name     : null,
        };
        this.user_options = user_options === null ? {} : user_options;
    }

    get options() {
        var keys = Object.keys( this.user_options );
        if ( keys.length === 0 ) { return this.default_options; }
        var res = {};
        keys.map( key => {
            res[ key ] = this.user_options.hasOwnProperty( key ) === true ? this.user_options[ key ] : this.default_options[ key ];
        });
        var default_keys = Object.keys( this.default_options );
        default_keys.map( key => {
            if ( !res.hasOwnProperty( key ) ) { res[ key ] = this.default_options[ key ]; }
        });
        return res;
    }

}


class base_module_v3 {

    constructor( options, service = null ) {
        var confobj = new module_config( options );
        this.state = {
            ...state,
            options : confobj.options,
            service : service === null ? base_service : service,
        };
        this.getters   = getters;
        this.actions   = actions;
        this.mutations = mutations;
    }

    static get_module( options, service = null ) {
        var obj = new base_module_v3( options, service );
        return {
            state     : obj.state,
            getters   : obj.getters,
            actions   : obj.actions,
            mutations : obj.mutations,
        };
    }

}

export default base_module_v3;

