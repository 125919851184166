//////////////////////////////

module.exports = (type) => {

    var schema = {
//      created: {
//          type      : type.VIRTUAL,
//          allowNull : true,
//          range     : true,
//      },
        customer_id: {
            type: type.INTEGER,
            allowNull: true,
            references: {
                model       : 'customers',
                key         : 'id',
                store_name  : 'customer',
            }
        },
//      'user_roles.id': {
//          type: type.INTEGER,
//          allowNull: true,
//          references: {
//              model       : 'roles',
//              key         : 'id',
//              store_name  : 'role',
//          }
//      },
    };

    return schema;
}


