////////////////////////////////////////

const user                        = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/user.schema.js');
const _user_create                = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_user_create.schema.js');
const object                      = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/object.schema.js');
const attribute                   = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/attribute.schema.js');
//const run                         = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/run.schema.js');
const customer                    = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/customer.schema.js');
const role                        = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/role.schema.js');
const order                       = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/order.schema.js');
const ddt                         = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/ddt.schema.js');
const _ddt_manager                = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_ddt_manager.schema.js');
const _search_order               = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_search_order.schema.js');
const ddt_item                    = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/ddt_item.schema.js');
const log                         = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/log.schema.js');
const _search_log                 = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_search_log.schema.js');
const _search_user                = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_search_user.schema.js');
const invoice_consistency         = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/invoice_consistency.schema.js');
const _invoice_consistency_filter = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_invoice_consistency_filter.schema.js');
const _search_automation          = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_search_automation.schema.js');
const automation                  = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/automation.schema.js');
const invoice_approval_state      = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/invoice_approval_state.schema.js');
const invoice_approval            = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/invoice_approval.schema.js');
const invoice_approval_line       = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/invoice_approval_line.schema.js');
const _invoice_approval_filter    = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_invoice_approval_filter.schema.js');
const _search_automation_list     = require('/home/silvio/SviluppoSync/botsupport-backend/models/schemas/_search_automation_list.schema.js');

export default {
    user,
    _search_user,
    _user_create,
    object,
    attribute,
//  run,
    customer,
    role,
    order,
    ddt,
    _ddt_manager,
    ddt_item,
    _search_order,
    log,
    _search_log,
    invoice_consistency,
    _invoice_consistency_filter,
    _search_automation,
    automation,
    invoice_approval_state,
    invoice_approval,
    invoice_approval_line,
    _invoice_approval_filter,
    _search_automation_list,
};
