
var options = {
    backend_url : 'http://localhost:5000',
    //backend_url : 'https://botsstagingapi.digitalautomations.it',
    //backend_url : 'https://botsapi.digitalautomations.it',
    gui: {
        app_label           : 'BOTS PORTAL',
        color               : '#006FC0',
        icon                : 'default.png',
        login_succeeded_url : '/list/user',
    },
    env: 'development',
};

if ( process.env.NODE_ENV === 'production' ) {
    // PRODUCTION //
    options.backend_url = 'https://botsapi.digitalautomations.it';
    options.env = 'production';
    // STAGING //
    //options.backend_url = 'https://botsstagingapi.digitalautomations.it';
    //options.gui.color = '#FFA726';
    //options.env = 'staging';
    // TEST //
    //options.backend_url = 'https://botstestapi.digitalautomations.it';
    //options.gui.color = '#A0A0A0';
    //options.env = 'test';
}

export default options;

