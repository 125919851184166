////////////////////////////////////////

import axios from "axios";

import base_module from '@/vmm-store/modules/base';
//import vmm_store from '../vmm-store';

//const base_service = vmm_store.service;

const options = {
    model_name    : 'automation',
    default_order : 'customer_id ASC|label ASC',
};

const state = {
    //params: { deleted: false },
};

const actions = {
    update({ commit, getters, dispatch }, data) {
        commit('start_request');
        const url = `${ getters.backend_url }/model/${ getters.model_name }/${ data.id }?include=${ data.include === true ? 'true' : 'false' }`;
        axios.patch( url, data.payload )
            .then( response => {
                let obj = response.data;
                commit('success', obj);
                commit('executed');
                commit('item_updated');
                dispatch( '_search_automation_automation_id_select/get_all', { params: {}, force_reload: true }, { root: true } );
            })
            .catch( error => {
                commit('error', error)
            });
    },
};

const mutations = {
};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

