<script>

import { mapActions, mapState, /*mapGetters*/ } from 'vuex';

export default {
    name: 'bot-list',
    props: [ '' ],
    components : {},
    computed : {
        ...mapState('bot', {
            items             : state => state.items,
            //sending           : state => state.sending,
            states            : state => state.states,
            loading           : state => state.loading,
            active_bot        : state => state.active_bot,
            active_bot_status : state => state.active_bot_status,
        }),
//      ...mapGetters( 'store', [ 'attribute' ] ),
    },
    watch: {
//      sending( new_value ) {
//          console.log( 'sending', new_value );
//      },
        active_bot( new_value ) {
            console.log( 'active_bot', new_value );
        },
    },
    methods : {
        ...mapActions( 'bot', {
            get_all    : 'get_all',
            _start_bot : 'start_bot',
            set_states : 'set_states',
        }),
        replace_new_line( str ) {
            return str.replace(/\n/g, '<br/>');
        },
        get_bg_variant( item ) {
            try {
                return item.config.ctrl.bg_variant;
            } catch ( error ) {
                return 'info';
            }
        },
        get_text_variant( item ) {
            try {
                return item.config.ctrl.text_variant;
            } catch ( error ) {
                return 'white';
            }
        },
        get_border_variant( item ) {
            try {
                return item.config.ctrl.border_variant;
            } catch ( error ) {
                return 'dark';
            }
        },
        start_bot( bot_name ) {
            this._start_bot( bot_name );
        },
        check_states() {
//          setTimeout( () => {
//              this.set_states();
//              this.check_states();
//          }, 3000);
        },
        get_label( item ) {
            let customer_id = this.$store.getters['authentication/customer_id'];
            return !customer_id ? `<span style="font-size: 0.5em"><kbd>${ item.customer.label }</kbd></span> ${ item.label }` : item.label;
        },
    },
    created() {
        this.get_all( { force_reload: true } );
        this.check_states();
    },
    data() {
        return {
        }
    },
}

</script>

<template>
    <div>
        <b-row>
            <b-col>
                <h2 style="text-align: center; margin-bottom: 30px">Controllo remoto automazioni</h2>
            </b-col>
        </b-row>
        <div v-if="loading" style="text-align: center; margin-top: 50px;">
            <b-spinner style="width: 3rem; height: 3rem;" label="Large Spinner" variant="info"></b-spinner>
        </div>
        <div v-else>
            <div v-if="items.length === 0" style="text-align: center; width: 70%; margin: auto; margin-top: 50px;">
                <b-alert variant="info" show><em>Non sono presenti automazioni abilitate al controllo remoto.</em></b-alert>
            </div>
            <div v-else>
                <b-row style="width: 96%; margin: auto;" cols-lg="2" cols-xl="2" cols-md="1" cols-sm="1" cols="1">
                    <b-col v-for="item in items" :key="'bot_' + item.id">
                        <b-jumbotron
                            :bg-variant     = "get_bg_variant( item )"
                            :text-variant   = "get_text_variant( item )"
                            :border-variant = "get_border_variant( item )"
                            :header-html    = "'<h3 style=\'font-weight: 900;\'>' + get_label( item ) + '</h3>'"
                            style           = "padding: 25px 25px"
                            >
                       <!-- <template #header>{{ item.label }}</template> -->
                            <template #lead>{{ replace_new_line( item.config.ctrl.short_description ) }}</template>
                            <p v-html="replace_new_line( item.config.ctrl.long_description )" style="font-size: 0.9em" />
                            <hr class="my-4">
                            <b-row>
                                <b-col cols="8">
                                    <b-alert v-if="states[ item.id ] === 'error'" variant="danger" show style="padding: 6px 12px">Si è verificato un errore nell'esecuzione del bot</b-alert>
                                </b-col>
                                <b-col cols="4">
                                    <div class="text-right">
                                        <!--
                                        <span>sending: {{sending}}</span>
                                        <span>state: {{states[ item.id ]}}</span>
                                        -->
                                        <b-button v-if="states[ item.id ] !== 'error'" :disabled="active_bot != null" variant="warning" @click="start_bot( item.automation_name )">
                                            <b-spinner v-if="active_bot === item.automation_name" small type="grow"></b-spinner>
                                            <span v-if="active_bot === item.automation_name && active_bot_status === 'processing'">&nbsp;&nbsp;in esecuzione...</span>
                                            <span v-else-if="active_bot === item.automation_name && active_bot_status === 'queued'">&nbsp;&nbsp;in coda...</span>
                                            <span v-else>Esegui</span>
                                        </b-button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-jumbotron>
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<style>
</style>
