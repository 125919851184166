<script>

import bnvbase from './bnvbase.vue';

export default {
    extends: bnvbase,
    created () {
        if ( typeof this.model === 'object' ) {
            try {
                this.model = JSON.stringify( this.model, null, 2 );
                this.is_json = true;
                this.binds.rows = Math.ceil( this.model.split(/\n/).length * 1.4 );
            } catch ( error ) {
                console.log('conversione a stringa di obj non riuscita');
            }
        }
    },
    methods: {
        _validate: function() {
            try {
                this._validate_allow_null();
            } catch ( error ) {
                return this._set_error( error );
            }
            if ( this.is_json ) {
                try {
                    this._validate_json();
                } catch ( error ) {
                    return this._set_error( error );
                }
            }
            return true;
        },
        _validate_json: function() {
            try {
                JSON.parse( this.model );
            } catch ( error ) {
                throw 'la stringa non è un json valido.';
            }
        },
        _return_model() {
            if ( !this.is_json ) {
                return this.model ? this.model : null;
            } else {
                return JSON.parse( this.model );
            }
        },
    },
    data () {
        return {
            is_json : false,
            binds   : { rows: 4 },
        }
    },
};

</script>

<template>

    <div class="form-group">
        <label :for="'bnv-form-' + name">{{ get_label() }}</label>
        <textarea
            v-bind="binds"
            ref="input"
            :id="'bnv-form-' + name"
            class="form-control"
            v-bind:class="{ 'is-invalid': error_component }"
            v-model="model"
            :placeholder="get_placeholder()"
            :aria-label="name"
            :aria-describedby="name"
            :disabled="disabled"
            :readonly="_readonly"
        ></textarea>
        <small v-if="helper" :id="'bnv-form-' + name + '-help'" class="form-text text-muted" v-html="_helper"></small>
        <div class="invalid-feedback">
            {{ get_error_message() }}
        </div>
    </div>

</template>



