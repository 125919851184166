//////////////////////////////////////////////////

import layouts from './layouts';

export default {

    invoice_approval_state: {},
    invoice_approval: {},
    invoice_approval_line: {},

    automation: {
        settings        : { size: 'sm', },
        automation_name : { label: 'Nome', read_only: 'edit' },
        label           : { label: 'Etichetta' },
        enabled         : { label: 'Visibile in statistiche' },
        customer_id     : { label: 'Cliente' },
        config          : { label: 'Configurazione', helper: 'il campo contiene un oggetto json' },
    },

    _search_automation: {
        settings      : { size: 'sm', },
        range         : { label: 'Data' },
        automation_id : { label: 'Automazione' },
    },

    _search_automation_list: {
        settings      : { size: 'sm', },
        customer_id   : { label: 'Cliente' },
    },

    _invoice_approval_filter: {
        settings                  : { size: 'sm', layout: 'single' },
        invoice_approval_state_id : { label: 'Stato' },
        data                      : { label: 'Data Documento' },
        data_scadenza_pagamento   : { label: 'Data Scadenza' },
        //cliente_piva              : { label: 'P.I. Cliente', placeholder: 'comincia a scrivere...' },
        //fornitore_piva            : { label: 'P.I. Fornitore', placeholder: 'comincia a scrivere...' },
        //cliente_cf                : { label: 'C.F. Cliente', placeholder: 'comincia a scrivere...' },
        fornitore_cf              : { label: 'P.IVA Fornitore', placeholder: 'comincia a scrivere...' },
        fornitore_denominazione   : { label: 'Fornitore', placeholder: 'comincia a scrivere...' },
    },

    _invoice_consistency_filter: {
        settings                      : { size: 'sm', layout: 'condensed', formlayout: layouts._invoice_consistency_filter, },
        'piva_cliente,cf_cliente'     : { label: 'P.I./CF Cliente', placeholder: 'comincia a scrivere...' },
        'piva_emittente,cf_emittente' : { label: 'P.I./CF Fornitore', placeholder: 'comincia a scrivere...' },
        piva_cliente                  : { label: 'P.I. Cliente', placeholder: 'comincia a scrivere...' },
        piva_emittente                : { label: 'P.I. Fornitore', placeholder: 'comincia a scrivere...' },
        cf_cliente                    : { label: 'C.F. Cliente', placeholder: 'comincia a scrivere...' },
        cf_emittente                  : { label: 'C.F. Fornitore', placeholder: 'comincia a scrivere...' },
        tipo_documento                : { label: 'Tipo Documento' },
        id_paese_cedente              : { label: 'Paese Fornitore' },
        id_paese_cessionario          : { label: 'Paese Cliente' },
        data_fattura_da               : { label: 'Da DataDoc.' },
        data_fattura_a                : { label: 'A DataDoc.' },
        data_consegna_da              : { label: 'Da DataRic.' },
        data_consegna_a               : { label: 'A DataRic.' },
        presente_in_TS                : { label: 'Presente Gestionale' },
        cont_corretta                 : { label: 'Contabilizzazione Corretta' },
        nota_di_credito               : { label: 'N.di Credito' },
        idsdi_mancante                : { label: 'SDI Mancante' },
    },

    invoice_consistency: {
        settings                : { size: 'sm', layout: 'condensed', formlayout: layouts.invoice_consistency, },
        customer_id             : { label: 'Cliente' },
        denominazione_emittente : { label: 'Denominazione Emittente' },
        denominazione_cliente   : { label: 'Denominazione Cliente' },
        piva_cliente            : { label: 'P.IVA Cliente' },
        cf_cliente              : { label: 'CF Cliente' },
        tipo_invio              : { label: 'Tipo Invio' },
        stato_file              : { label: 'Stato File' },
        id_invio                : { label: 'ID invio' },
        cliente_fornitore       : { label: 'Cliente / Fornitore' },
        numero_fattura          : { label: 'N. Fattura' },
        data_fattura            : { label: 'Data Fattura' },
        imponibile              : { label: 'Imponibile' },
        imposta                 : { label: 'Imposta' },
        data_consegna           : { label: 'Data Consegna' },
        tipo_documento          : { label: 'Tipo Documento' },
        piva_emittente          : { label: 'P.IVA Emittente' },
        cf_emittente            : { label: 'CF Emittente' },
        id_paese_cedente        : { label: 'ID Paese Cedente' },
        id_paese_cessionario    : { label: 'ID Paese Cessionario' },
        presente_in_TS          : { label: 'Presente nel Gestionale' },
        cont_corretta           : { label: 'Cont. Corretta' },
        nota_di_credito         : { label: 'Nota di Credito' },
        idsdi_mancante          : { label: 'SDI Mancante' },

    },

    _search_order: {
        settings : { size: 'sm', },
        'date_managed__gte' : { label: 'Gestito Da' },
        'date_managed__lte' : { label: 'Gestito A' },
        'completed'         : { label: 'Gestito' },
        ddt_company_id      : { label: 'Fornitore' },
    },

    _search_log: {
        settings     : { size: 'sm', },
        created__gte : { label: 'Creato dal' },
        created__lte : { label: 'Creato al' },
        controller   : { label: 'Controller', helper: 'campo contiene' },
        action       : { label: 'Action', helper: 'campo contiene' },
        user_id      : { label: 'Utente' },
        customer_id  : { label: 'Customer' },
        tags         : { label: 'Tag' },
    },

    _search_user: {
        settings        : { size: 'sm', },
        created         : { label: 'Data Creazione' },
        customer_id     : { label: 'Customer' },
        'user_roles.id' : { label: 'Ruolo' },
//      controller   : { label: 'Controller', helper: 'campo contiene' },
//      action       : { label: 'Action', helper: 'campo contiene' },
//      user_id      : { label: 'Utente' },
//      tags         : { label: 'Tag' },
    },

    log: {
        settings : { size: 'sm', },
    },

    order: {
        settings : { size: 'sm', },
    },

    ddt: {
        settings : { size: 'sm', },
    },

    _ddt_manager: {
        settings : { size: 'sm', },
    },

    role: {
        settings : { size: 'sm', },
        label : { label: 'Nome' },
        config : { label: 'Configurazione', helper: 'il campo contiene un oggetto json' }
    },

    ddt_item: {
        settings : { size: 'sm', },
    },

    customer: {
        settings : { size: 'sm', },
        label    : { label: 'Nome' },
        created  : { skip: true },
        config   : { skip: false, helper: 'il campo contiene un oggetto json' },
        id       : { skip: false, readonly: true },
    },

    _user_create: {
        settings    : { size: 'sm', },
        customer_id : { label: 'Customer', skip: false, },
        first_name  : { label: 'Nome', skip: false, },
        last_name   : { label: 'Cognome', skip: false, },
        email       : { label: 'Email', skip: false, },
        enabled     : { label: 'Abilitato', skip: false, },
        role_id     : { label: 'Ruolo', skip: 'edit', },
        send_email  : { label: 'Invia Email', skip: false, },
        created     : { label: '', skip: true, },
        hash        : { label: '', skip: true, },
        config      : { label: '', skip: true, },
        id          : { label: '', skip: true, },
    },

    user: {
        settings    : { size: 'sm', },
        customer_id : { label: 'Customer', skip: false, },
        first_name  : { label: 'Nome', skip: false, },
        last_name   : { label: 'Cognome', skip: false, },
        email       : { label: 'Email', skip: false, },
        enabled     : { label: 'Abilitato', skip: false, },
        id          : { label: 'id' },
        role_id     : { label: 'Ruolo', /*skip: 'edit', readonly: 'edit'*/ },
    },

    attribute: {
        settings  : { size: 'sm', },
        object_id : { label: 'Oggetto' },
        id        : { skip: false, label: 'Chiave' },
        value     : { label: 'Value' },
        created   : { skip: true },
        config    : { skip: true },
    },

    object: {
        settings    : { size: 'sm', },
        id          : { skip: false, label: 'Nome' },
        customer_id : { label: 'Customer', skip: false, },
        created     : { skip: true },
        config      : { skip: true },
    },

    run: {
        settings    : { size: 'sm', },
    },

}

// GRAFICA
// label............ etichetta del campo
// skip............. non mostrare in maschera
// displayLabel..... mosra la label del campo in maschera
// helper........... helper da scrivere sotto al campo
// placeholder...... placeholder

// VALIDAZIONE
// maxValue......... valore massimo (number)
// minValue......... valore minimo (number)
// maxLength........ lunghezza massima (stringa)
// minLength........ lunghezza minima (stringa)
