//////////////////////////////////////////////////

import cmps from '@/components/ListComponents';

import ddt_details              from '@/ddt/details';
import log_details              from '@/log/details';
import ddt_details_manager      from '@/ddt/details_ddt_manager';
import inv_app_operator_details from '@/views/invoice_approval/operator_details.vue';
import inv_app_ceo_details      from '@/views/invoice_approval/ceo_details.vue';

export default {

    invoice_approval_state: {},

    invoice_approval_ceo: {
        title_list    : 'DDT',
        order         : 'data_scadenza_pagamento ASC',
        add           : false,
        edit          : false,
        include       : 'invoice_approval_state, invoice_approval_line',
        detailsShow   : inv_app_ceo_details,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'checkbox', label: '', component: cmps.inv_app_checkbox },
            { key: 'data', label: 'Data', formatter: 'format_date', sortable: true },
            { key: 'fornitore_denominazione', label: 'Denominazione' },
            { key: 'fornitore_cf', label: 'P.IVA / C.F.' },
            { key: 'numero', label: 'Numero' },
            { key: 'importo', label: 'Importo', formatter: 'convert_money' },
            { key: 'invoice_approval_state.label', label: 'Stato', component: cmps.inv_app_state },
            { key: 'modalita_pagamento', label: 'Mod.Pag.', component: cmps.inv_app_modpag },
            //{ key: 'data_riferimento_termini_pagamento', label: 'DRTP', formatter: 'format_date', sortable: true },
            { key: 'data_scadenza_pagamento', label: 'Scadenza', formatter: 'format_date', sortable: true },
        ],
    },

    invoice_approval: {
        title_list    : 'DDT',
        order         : 'data_scadenza_pagamento ASC',
        add           : false,
        edit          : false,
        include       : 'invoice_approval_state, invoice_approval_line',
        detailsShow   : inv_app_operator_details,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            //{ key: 'checkbox', label: '', component: cmps.inv_app_checkbox },
            { key: 'data', label: 'Data', formatter: 'format_date', sortable: true },
            { key: 'fornitore_denominazione', label: 'Denominazione' },
            { key: 'fornitore_cf', label: 'P.IVA' },
            { key: 'numero', label: 'Numero' },
            { key: 'importo', label: 'Importo', formatter: 'convert_money' },
            { key: 'invoice_approval_state.label', label: 'Stato', component: cmps.inv_app_state },
            { key: 'modalita_pagamento', label: 'Mod.Pag.', component: cmps.inv_app_modpag },
            //{ key: 'data_riferimento_termini_pagamento', label: 'DRTP', formatter: 'format_date', sortable: true },
            { key: 'data_scadenza_pagamento', label: 'Scadenza', formatter: 'format_date', sortable: true },
        ],
    },

    invoice_approval_line: {},

    automation: {
        title_list    : 'AUTOMAZIONI',
        order         : 'customer_id ASC|label ASC',
        add           : true,
        edit          : true,
        include       : true,
        destroy       : true,
        detailsShow   : false,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'customer.label', sortable: true, label: 'Customer' },
            { key: 'label', label: 'Etichetta' },
            { key: 'automation_name', label: 'Nome' },
            { key: 'enabled', label: 'Visibile in statistiche', formatter: 'convert_boolean' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', sortable: true },

//          { key: 'type', component: cmps.log_type, label: 'Tipo' },
//          { key: 'tag', component: cmps.log_tags, label: 'Tags' },
//          { key: 'controller', label: 'Controller' },
//          { key: 'action', label: 'Action' },
//          { key: 'user.email', label: 'User' },
//          { key: 'message', label: 'Log' },
//          { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', sortable: true },
        ],
    },

    invoice_consistency: {
        title_list    : 'CONSISTENZA FATTURE',
        order         : 'data_consegna DESC|id ASC',
        add           : false,
        edit          : false,
        include       : 'invoice_consistency_doc_type',
        //detailsShow   : false,
        //detailsShow   : log_details,
        paginate      : true,
        details_class : '',
        submit        : false,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            // table
            { key: 'denominazione_emittente', label: 'Fornitore', sortable: true },
            { key: 'denominazione_cliente', label: 'Cliente', sortable: true },
            { key: 'numero_fattura', label: 'N. Fattura', sortable: true },
            { key: 'data_fattura', label: 'Data Fattura', htmlformatter: 'format_date', sortable: true },
            { key: 'data_consegna', label: 'Data Consegna', htmlformatter: 'format_date', sortable: true },
            { key: 'imponibile', label: 'Imponibile', formatter: 'convert_money', sortable: true },
            { key: 'imposta', label: 'Imposta', formatter: 'convert_money', sortable: true },
            { key: 'importo', label: 'Importo', formatter: 'convert_money', sortable: true },
            // details
            { key: 'piva_cliente', label: 'P.IVA Cliente', display: false },
            { key: 'cf_cliente', label: 'CF Cliente', display: false },
            { key: 'tipo_invio', label: 'Tipo Invio', display: false },
            { key: 'stato_file', label: 'Stato File', display: false },
            { key: 'id_invio', label: 'ID invio', display: false },
            { key: 'cliente_fornitore', label: 'Cliente / Fornitore', display: false },
            { key: 'tipo_documento', label: 'Tipo Documento', display: false, component: cmps.ic_doc_type },
            { key: 'piva_emittente', label: 'P.IVA Fornitore', display: false },
            { key: 'cf_emittente', label: 'CF Fornitore', display: false },
            { key: 'id_paese_cedente', label: 'ID Paese Cedente', display: false },
            { key: 'id_paese_cessionario', label: 'ID Paese Cessionario', display: false },
            { key: 'presente_in_TS', label: 'Presente in TS', display: false, formatter: 'convert_boolean' },
            { key: 'cont_corretta', label: 'Cont. Corretta', display: false, formatter: 'convert_boolean' },
            { key: 'nota_di_credito', label: 'Nota di Credito', display: false, formatter: 'convert_boolean' },
            //{ key: 'idsdi_mancante', label: 'SDI Mancante', formatter: 'convert_boolean', display: false },
            { key: 'time_rilevazione', label: 'Time Rilevazione', display: false, component: cmps.ic_time_rilevazione },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il', display: false },
        ],
    },

    ddt_item: {},

    log: {
        title_list    : 'LOG',
        order         : 'created DESC',
        add           : false,
        edit          : false,
        include       : 'user',
        //detailsShow   : false,
        detailsShow   : log_details,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'type', component: cmps.log_type, label: 'Tipo' },
            { key: 'tag', component: cmps.log_tags, label: 'Tags' },
            { key: 'controller', label: 'Controller' },
            { key: 'action', label: 'Action' },
            { key: 'user.email', label: 'User' },
            { key: 'message', label: 'Log' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Data', sortable: true },
        ],
    },

    role: {
        title_list    : 'RUOLI',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        include       : false,
        detailsShow   : false,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'label', label: 'Nome' },
        ],
    },

    ddt: {
        title_list    : 'DDT',
        order         : 'created ASC',
        add           : false,
        edit          : false,
        include       : 'ddt_company,ddt_item,order',
        detailsShow   : ddt_details,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'numero', label: 'Numero DDT', component: cmps.ddt_numero_magazziniere },
            { key: 'data', label: 'Data', formatter: 'format_date', sortable: true },
            { key: 'ddt_company.dominio', label: 'Fornitore', sortable: true },
            { key: 'rowsnumber', component: cmps.ddt_rowsnumber, label: 'Prodotti' },
            { key: 'status', component: cmps.ddt_status, label: 'Stato', sortable: false },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il', sortable: true },
        ],
    },

    _ddt_manager: {
        title_list    : 'DDT',
        order         : 'created ASC',
        add           : false,
        edit          : false,
        include       : 'ddt_company,ddt_item,order',
        detailsShow   : ddt_details_manager,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'numero', label: 'Numero DDT', component: cmps.ddt_numero_manager },
            { key: 'data', label: 'Data', formatter: 'format_date', sortable: true },
            { key: 'ddt_company.dominio', label: 'Fornitore', sortable: true },
            { key: 'rowsnumber', component: cmps.ddt_rowsnumber, label: 'Prodotti' },
            { key: 'status', component: cmps.ddt_status, label: 'Stato', sortable: false },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il', sortable: true },
            { key: 'date_managed', label: 'Gestito il', component: cmps.ddt_date_managed, sortable: true },
            { key: 'navision', label: 'Navision', htmlformatter: 'convert_boolean' }
        ],
    },

    order: {
        title_list    : 'Ordini',
        order         : 'created ASC',
        add           : false,
        edit          : false,
        include       : 'customer',
        details       : false,
        paginate      : true,
        details_class : 'd-lg-none',
        clickable     : true,
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'ordine_numero', label: 'Numero' },
            { key: 'ordine_relativo_numero', label: 'Numero Relativo' },
            //{ key: 'ordine_data', label: 'Data', htmlformatter: 'format_date' },
            { key: 'completed', label: 'Completo', htmlformatter: 'convert_boolean', sortable: true },
            { key: 'locked', label: 'Bloccato', htmlformatter: 'convert_boolean', sortable: true },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il', sortable: true },
        ],
    },

    customer: {
        title_list    : 'Clienti',
        order         : 'label ASC',
        add           : true,
        edit          : true,
        destroy       : true,
        include       : 'user',
        details       : true,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'id', label: 'id' },
            { key: 'label', label: 'Nome' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il' },
        ],
    },

    user: {
        title_list    : 'Utenti',
        order         : 'customer_id ASC|last_name ASC|first_name ASC',
        add           : true,
        edit          : true,
        destroy        : true,
        include       : 'customer,role',
        details       : true,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'id', label: 'id' },
            { key: 'last_name', label: 'Cognome' },
            { key: 'first_name', label: 'Nome' },
            { key: 'customer.label', label: 'Cliente' },
            { key: 'user_roles', component: cmps.user_roles, label: 'Ruoli' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il', display: false },
            { key: 'email', label: 'Email', display: false },
        ],
    },

    attribute: {
        title_list    : 'Attributi',
        order         : 'object_id ASC|id ASC',
        add           : false,
        edit          : false,
        include       : 'object',
        details       : false,
        paginate      : true,
        details_class : 'd-lg-none',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'id', label: 'Key' },
            { key: 'value', label: 'Value', component: cmps.attribute_value },
            { key: 'object.label', label: 'Lista' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il' },
        ],
    },

    object: {
        title_list    : 'Oggetti',
        order         : 'id ASC',
        add           : false,
        edit          : false,
        include       : 'customer',
        details       : false,
        paginate      : true,
        details_class : '',
        submit        : true,
        qryOptions    : {},
        initialFilter : {},
        fields: [
            { key: 'id', label: 'Nome' },
            { key: 'customer.label', label: 'Cliente' },
            { key: 'created', htmlformatter: 'format_datetime_t2', label: 'Creato il' },
        ],
    },

};


