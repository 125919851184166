//////////////////////////////

module.exports = (type) => {
    var schema = {
        first_name: {
            type: type.STRING(64),
            allowNull: false,
        },
        last_name: {
            type: type.STRING(64),
            allowNull: false,
        },
        email: {
            type: type.STRING(64),
            isEmail: true,
            allowNull: false,
        },
        created: {
            type: type.DATE,
            allowNull: false,
            defaultValue : type.NOW,
        },
        hash: {
            type: type.STRING(128),
            allowNull: false,
        },
        enabled: {
            type: type.BOOLEAN,
            defaultValue: true
        },
        config: {
            type: type.JSONB,
            allowNull: false,
            defaultValue: {},
        },
        customer_id: {
            type: type.INTEGER,
            allowNull: false,
            references: {
                model       : 'customers',
                key         : 'id',
                constraints : true,
                store_name  : 'customer',
            }
        },
        deleted: {
            type: type.BOOLEAN,
            defaultValue: false
        },
        id: {
            type: type.INTEGER,
            primaryKey: true,
            autoIncrement: true
        },
    };

    return schema;
}

