/////////////////////////////////////////////////

//const module = require('./path_to_module.js');

class GraphConfig {

    constructor( config = {} ) {
        this.config = config;
        this.default_hourly_rate = 42;
        this.default_relation_success_min = 42;
    }

    // CONFIG

    get hourly_rate() {
        return this.config.hourly_rate || this.default_hourly_rate;
    }

    get relation_success_min() {
        return this.config.relation_success_min || this.default_relation_success_min;
    }

    // GRAPHS

    get graphs() {
        return this.config.graphs || {};
    }

    get show_trend() {
        if ( !this.graphs.hasOwnProperty('show_trend') ) { return true; }
        return this.graphs.show_trend;
    }

    get show_business_goal_achieved() {
        if ( !this.graphs.hasOwnProperty('show_business_goal_achieved') ) { return true; }
        return this.graphs.show_business_goal_achieved;
    }

    get show_performance_statistics() {
        if ( !this.graphs.hasOwnProperty('show_performance_statistics') ) { return true; }
        return this.graphs.show_performance_statistics;
    }

    get show_missed_objective_detail() {
        if ( !this.graphs.hasOwnProperty('show_missed_objective_detail') ) { return true; }
        return this.graphs.show_missed_objective_detail;
    }

    get label_trend() {
        return this.graphs.label_trend || 'Trend';
    }

    get label_business_goal_achieved() {
        return this.graphs.label_business_goal_achieved || 'Macchina vs umano';
    }

    get label_performance_statistics() {
        return this.graphs.label_performance_statistics || 'Statistica di funzionamento';
    }

    get label_missed_objective_detail() {
        return this.graphs.label_missed_objective_detail || 'Supporto umano';
    }

    // BOXES

    get boxes() {
        return this.config.boxes || {};
    }

    get label_total() {
        return this.boxes.label_total || 'Item totali';
    }

    get tooltip_total() {
        return this.config.tooltip_total || 'Item totali';
    }

    get label_total_bots() {
        return this.boxes.label_total_bots || 'Item gestiti dai bot';
    }

    get tooltip_total_bots() {
        return this.boxes.tooltip_total_bots || 'Item gestiti dai bot';
    }

    get label_saved_hours() {
        return this.boxes.label_saved_hours || 'Ore risparmiate';
    }

    get tooltip_saved_hours() {
        return this.boxes.tooltip_saved_hours || 'Ore risparmiate';
    }

    get label_savings() {
        return this.boxes.label_savings || 'Risparmio';
    }

    get tooltip_savings() {
        return this.boxes.tooltip_savings || 'Risparmio';
    }

    get show_total() {
        return this.boxes.hasOwnProperty('show_total') ? this.boxes.show_total : true;
    }

    get show_total_bots() {
        return this.boxes.hasOwnProperty('show_total_bots') ? this.boxes.show_total_bots : true;
    }

    get show_saved_hours() {
        return this.boxes.hasOwnProperty('show_saved_hours') ? this.boxes.show_saved_hours : true;
    }

    get show_savings() {
        return this.boxes.hasOwnProperty('show_savings') ? this.boxes.show_savings : true;
    }

}

export default GraphConfig;

