<script>

export default {
    name: 'block-widget',
    props: [ 'title', 'format', 'loading', 'value', 'postfix', 'tooltip' ],
    components : {},
    computed : {
        label() {
            let label;
            if ( !this.format || this.format === 'currency' ) {
                label = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format( this.value );
            } else if ( this.format === 'number' ) {
                label = new Intl.NumberFormat('it-IT', {}).format( this.value );
            } else if ( this.format === 'string' ) {
                label = this.value;
            }
            return this.postfix ? label + ' ' + this.postfix : label;
        },
    },
    watch: {},
    methods : {
    },
    created() {},
    data() {
        return {
        }
    },
}

</script>

<template>
    <b-card
        border-variant="light"
        class = "shadow"
        >
        <b-card-text style="font-family: Montserrat,sans-serif;">
            <b-row>
                <b-col>{{ title }}</b-col>
                <b-col sm="auto" v-b-tooltip.hover.topright="tooltip"><icon style="fill: #007BFF;" name="question-circle" scale="1"/></b-col>
            </b-row>
        </b-card-text>
        <b-card-title style="font-family: Montserrat,sans-serif;">{{ label }}</b-card-title>
    </b-card>
</template>

<style>
</style>
