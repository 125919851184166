<script>

import router from '@/router';
import config from '@/config';
import { /*mapActions,*/ mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            env: config.env,
        }
    },
    computed: {
        user () {
            return JSON.parse( localStorage.getItem('user') );
        },
        icon_url () {
            if ( config.gui.hasOwnProperty('icon') && config.gui.icon ) { return config.backend_url + `/icons/${ config.gui.icon }`; }
            return undefined;
        },
        color () {
            if ( config.gui.hasOwnProperty('color') && config.gui.color ) { return config.gui.color; }
            return undefined;
        },
        app_label () {
            if ( config.gui.hasOwnProperty('app_label') && config.gui.app_label ) { return config.gui.app_label; }
            return undefined;
        },
        selected: {
            get() { return this.$router.currentRoute.path; },
        },
        ...mapGetters( 'authentication', [ 'can_do', ] ),
        ...mapState('authentication', {
            roles : state => state.roles,
        }),
        is_manager() {
            return this.roles.includes('manager');
        },
    },
    created() {},
    methods: {
        navigate_to(route) {
            if ( route === this.selected ) { return; }
            this.$router.push( route );
        },
        logout() {
            router.push('/login');
        },
    },
}

</script>

<template>

    <div style="margin-bottom: 15px;">
        <b-navbar toggleable="lg" type="dark" :variant="color ? '' : 'info'" fixed="top" v-bind:style="color ? 'background-color:' + color : ''">
            <!-- <b-navbar-brand href="#">
                <b-img v-if="icon_url" :src="icon_url" fluid rounded="circle" width="25" alt="customer logo" style="background-color: white; padding: 2px;"></b-img>
                {{ app_label }}
            </b-navbar-brand> -->

            <b-navbar-brand href="#" @click="navigate_to('/home')">
                <!-- <b-img v-if="icon_url" :src="icon_url" fluid rounded="circle" width="25" alt="customer logo" style="background-color: white; padding: 2px;"></b-img> -->
                &nbsp;<span style="font-weight: 800;">Bots Manager</span>
            </b-navbar-brand>

            <b-navbar-toggle class="ml-auto" target="nav-collapse"></b-navbar-toggle>
    
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="" v-if="is_manager">
                    <b-nav-item href="#" @click="navigate_to('/list/order')">Ordini</b-nav-item>
                </b-navbar-nav>
                <b-navbar-nav class="" v-if="is_manager">
                    <b-nav-item href="#" @click="navigate_to('/listmn/ddt')">DDT</b-nav-item>
                </b-navbar-nav>

                <b-navbar-nav>
                     <b-nav-item-dropdown text="Settings" v-if="can_do('GET:/model/customer') || can_do('GET:/model/user') || can_do('GET:/model/role')">
                        <b-dropdown-item href="#" @click="navigate_to('/list/customer')" v-if="can_do('GET:/model/customer')">Clienti</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/user')" v-if="can_do('GET:/model/user')">Utenti</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/role')" v-if="can_do('GET:/model/role')">Ruoli</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/log')" v-if="can_do('GET:/model/log')">Log</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/automation')" v-if="can_do('GET:/model/automation')">Automazioni</b-dropdown-item>
                     </b-nav-item-dropdown>
                     <b-nav-item-dropdown text="Data" v-if="can_do('GET:/model/object') || can_do('GET:/model/attribute')">
                        <!--
                        <b-dropdown-item href="#" @click="navigate_to('/list/object')" v-if="can_do('GET:/model/object')">Liste</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/attribute')" v-if="can_do('GET:/model/attribute')">Elementi</b-dropdown-item>
                        -->
                        <b-dropdown-item href="#" @click="navigate_to('/list/invoice_consistency')" v-if="can_do('GET:/model/invoice_consistency')">Coerenza Fatture</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/graph/result')" v-if="can_do('GET:/model/stat_result')">Statistiche Automazioni</b-dropdown-item>
                        <b-dropdown-item href="#" @click="navigate_to('/list/bot')" v-if="can_do('GET:/model/automation')">Controllo Remoto Bots</b-dropdown-item>
                     </b-nav-item-dropdown>
                </b-navbar-nav>
    
                <b-navbar-nav class="mx-auto" v-if="env !== 'production'">
                    <b-nav-text style="color: white; font-weight: 800">	&lt; {{ env.toUpperCase() }} /&gt; </b-nav-text>
                </b-navbar-nav>
                <!-- Right aligned nav items -->
                <b-navbar-nav class="ml-auto">
                    <b-nav-item href="#" @click="logout()">logout</b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>

</template>

