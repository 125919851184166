////////////////////////////////////////

import axios  from "axios";
import moment from "moment-timezone";

import base_module from '@/vmm-store/modules/base';
import vmm_store from '../vmm-store';

const base_service = vmm_store.service;

const options = {
    model_name    : 'automation',
    default_order : 'label ASC',
};

const state = {
    //sending           : false,
    states            : {},
    errors            : {},
    error_interval    : 5,
    active_bot        : null,
    active_bot_status : null,
};

const actions = {
    get_all( { commit, state, getters, dispatch }, data = {} ) { // TODO new
        commit('start_request');
        var params   = JSON.parse( JSON.stringify( state.params ) );
        params.order = getters.order;
        if ( data.params ) {
            Object.keys( data.params ).map( key => {
                if ( ![ '', null, undefined ].includes( data.params[ key ] ) ) {
                    params[ key ] = data.params[ key ];
                } else {
                    delete params[ key ];
                }
            });
        }
        var new_params_string = JSON.stringify( params, Object.keys(params).sort() );
        var old_params_string = JSON.stringify( state.params, Object.keys(state.params).sort() );
        var force_reload = new_params_string !== old_params_string;
        if ( force_reload ) { commit( 'set_params', { params } ); }
        const url = base_service.get_all( getters.backend_url, getters.model_name, params );
        if ( state.map === null || force_reload || state.force_reload === true || data.force_reload === true ) {
            axios.get( url )
                .then( res => {
                    let response = res.data;
                    if ( params.paginate === true ) { // gestione paginator
                        commit('success_list', response.data);
                        commit('set_total', response.tot);
                        commit('set_page', response.page);
                        commit('set_rows_per_page', response.rows_per_page);
                    } else {
                        commit('success_list', response);
                        //dispatch('set_states');
                    }
                    dispatch('check_bots_status');
                })
                .catch( error => {
                    commit('error', error)
                    //dispatch('set_states');
                });
        } else {
            commit('stop_request');
        }
    },
    async check_bots_status( { /*commit,*/ dispatch, getters } ) {
        let url = `${ getters.backend_url }/model/rcb/status`;
        await axios.get( url )
            .then( res => {
                dispatch('set_states', res.data);
            })
        setTimeout( () => {
            dispatch('check_bots_status');
        }, 5000);
    },
    set_states( { commit }, payload ) {
        let bots = Object.keys( payload );
        let active = false;
        for ( var i = 0; i < bots.length; i++ ) {
            if ( payload[ bots[ i ] ] !== 'processed' ) {
                commit( 'set_status', { bot: bots[ i ], status: payload[ bots[ i ] ] } );
                active = true;
                break;
            }
        }
        if ( !active ) { commit('set_status'); }
    },
    start_bot( { getters, commit }, bot_name ) {
        let url = `${ getters.backend_url }/model/rcb/${ bot_name }`;
        //commit('set_sending');
        axios.patch( url, { status: 'queued' } )
            .then( ( /*res*/ ) => {
                commit('set_status', { bot: bot_name, status: 'queued' });
                //commit('set_sending', false );
            })
            .catch( error => {
                console.log( error );
                commit('set_error', bot_name );
                //commit('set_state', null, bot_name );
                //commit('set_sending', false );
            })
    },
};

const mutations = {
//  set_state( state, bot, bot_id = null ) {
//      if ( bot === null ) {
//          bot = state.map.get( bot_id );
//      }
//      bot_id = bot.id;
//      let states = state.states;
//      let diff;
//      let items_map = state.map;
//      if ( state.errors.hasOwnProperty( String( bot_id ) ) ) {
//          // error
//          diff = moment().tz('Europe/Rome').diff( moment( state.errors[ bot_id ] ), 'minutes' );
//          if ( diff <= state.error_interval ) {
//              states[ bot_id ] = 'error';
//          } else {
//              delete state.errors[ bot_id ];
//          }
//      } else {
//          // state
//          items_map.set( bot_id, bot );
//          let last_dispatch = moment( bot.config.ctrl.last_dispatch );
//          let interval      = bot.config.ctrl.interval;
//          diff              = moment().tz('Europe/Rome').diff( last_dispatch, 'minutes' );
//          //console.log( diff, interval );
//          states[ bot_id ]  = diff <= interval ? 'active' : 'inactive';
//          state.map = items_map;
//          //state.items = Array.from( items_map.values() );
//      }
//      state.states = {};
//      //console.log( states );
//      state.states = states;
//  },
    set_status( state, payload = null ) {
        if ( !payload ) {
            state.active_bot = null;
            state.active_bot_status = null;
        } else {
            state.active_bot = payload.bot;
            state.active_bot_status = payload.status;
        }
    },
    set_error( state, bot_id ) {
        let errors       = state.errors;
        errors[ bot_id ] = moment().tz('Europe/Rome').format();
        state.errors     = {};
        state.errors     = errors;
    },
//  set_sending( state, value = true ) {
//      state.sending = value;
//  },
    success_list( state, _items ) {
        var map = new Map();
        let ctrl_enabled;
        let items = [];
        _items.map( x => {
            try {
                ctrl_enabled = x.config.ctrl.enabled;
            } catch ( error ) {
                ctrl_enabled = false;
            }
            if ( ctrl_enabled === true ) {
                map.set( x.id, x );
                items.push( x );
            }
        });
        state.items         = items;
        state.map           = map;
        state.loading       = false;
        state.loading_by_id = false;
        state.error         = false;
        state.force_reload  = false;
        state.executed = true;
        setTimeout( () => {
            state.executed = false;
        }, 200);
    },
};

const getters = {};

class new_module extends base_module {

    constructor ( new_options = {} ) {
        Object.keys( new_options ).map( x => {
            options[ x ] = new_options[ x ];
        });
        super( options );
    }

    static get_module( new_options = {} ) {
        var obj = new new_module( new_options );
        return {
            namespaced: true,
            state: {
                ...obj.state,
                ...state,
            },
            getters: {
                ...obj.getters,
                ...getters,
            },
            actions: {
                ...obj.actions,
                ...actions,
            },
            mutations: {
                ...obj.mutations,
                ...mutations,
            },
        }
    }

}

export default new_module;

